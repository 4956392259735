import { CommunicationType } from '@src/constants'

export const enum CountryCode {
  'GB' = 'GB',
  'global' = 'global',
}

export interface Organization {
  id?: number
  name: string
  integration: {
    integration_id: number
    tenant_id: string
  } | null
  settings: {
    softledger_sync_enabled?: boolean
    xero_sync_enabled?: boolean
    quickbooks_sync_enabled?: boolean
    country_code: CountryCode
  }
  watchers: {
    users: number[]
    departments: number[]
    roles: number[]
  }
}
export interface OrganizationListItem {
  id: number
  name: string
  integration: {
    integration_id: number
    tenant_id: string
  } | null
  watchers: {
    users: number[]
    departments: number[]
    roles: number[]
  }
  settings: {
    softledger_sync_enabled: boolean
    quickbooks_sync_enabled?: boolean
    xero_sync_enabled: boolean
  }
}

export interface ContactDetails {
  first_name: string | null
  last_name: string | null
  email: string | null
  phone: string | null
  website: string | null
  registration_number: string | null
}
export interface AdditionalFinanceDetails {
  bank_name?: string | null
  bank_address?: string | null
  crypto_address?: string | null
  crypto_currency?: string | null
  sort_code?: string | null
}
export interface Finance {
  id?: number
  bank_account_name: string | null
  bank_account_number: string | null
  bank_account_details: string | null
  tax_id_number: string | null
  currency: string | null
  routing_number: string | null
  swift_code: string | null
  additional_data: AdditionalFinanceDetails | null
}
export interface Address {
  address: string | null
  zip: string | null
  city: string | null
  country: string | null
  state: string | null
}

export interface Contact {
  name: string
  account_number: string | null
  contact_details: ContactDetails | null
  files: File[] | string[]
  organization: number
  active_financial_details?: number | null
  financial_details?: Finance | null
  address_details: {
    billing: Address | null
    delivery: Address | null
  } | null
  id?: number
  xero_contact_id?: string
  settings?: { xero_contact_id: string | null; softledger_id: string | null }
  auth_user?: number | null
  invite_id?: number | null
  user_id?: number
  party?: ContactParty
  sync?: boolean
}

export interface ContactListItem {
  name: string
  account_number: string | null
  id: number
  organization_id: number
  settings: {
    xero_contact_id: number | null
    softledger_id: number | null
    quickbooks_id: number | null
  }
  email?: string
  user_id?: number
  invite_id?: number | null
  bill_count: number
  invoice_count: number
  po_count: number
  quote_count: number
}

// contracts

export enum ContractStatuses {
  Active = 'active',
  Terminated = 'terminated',
  Expired = 'expired',
  NotActive = 'not_active',
}

export const enum ContactParty {
  vendor = 'vendor',
  customer = 'customer',
}

interface Contract {
  amount?: number | null
  auto_renewal: boolean
  contract_number: string
  currency?: string | null
  expiration_date?: string
  file_link?: string
  files: string[]
  manager: number
  name: string
  party?: ContactParty
  signing_date?: string
  start_date?: string
  status?: ContractStatuses
  team?: number
  terms_and_conditions?: string
}
export interface ContractRequest extends Contract {
  collaboration: number
  agreement_type?: number
  department?: number | null
}
export interface ContractResponse extends Contract {
  id: number
  collaboration: {
    name: string
    id: number
  }
  agreement_type?: {
    id: number
    name: string
    team: number
  }
  department?: {
    id: number
    name: string
  }
  created_at: string
}
export interface ContractListItemResponse {
  id: number
  vendor: {
    name: string
    id: number
  }
  status: ContractStatuses
  name: string
  expiration_date: string
  contract_number: string
  agreement_type: { id: number; team: number; name: string }
  party: ContactParty
}

export interface ContractListItem {
  id: number
  collaboration: {
    name: string
    id: number
  }
  status: ContractStatuses
  name: string
  expiration_date: string
  type: string
  contract_number: string
  agreement_type: { id: number; team: number; name: string }
  party: string
}

export interface ContractLineItemsResponse {
  results: ContractListItem[]
  count: number
}

export interface ContractFiltersRequest {
  status?: string
  collaboration?: number
  search?: string
  page?: number
  organization?: number
  size?: number
  agreement_type?: number
  contract_number?: string
  expiration_date_start?: string
  expiration_date_end?: string
  ordering?: string
}

export interface FilterOption {
  id: number
  name: string
}

export interface ContractFiltersResponse {
  agreement_types: FilterOption[]
  departments: FilterOption[]
  statuses: ContractStatuses[]
  collaborations: FilterOption[]
}

export enum ContractReminderFrequency {
  OncePerDay = 'once_per_day',
  OncePerWeek = 'once_per_week',
  OncePerMonth = 'once_per_month',
}

export enum ContractDeltaUnits {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export interface ContractReminder {
  enabled: boolean
  communication?: CommunicationType
  frequency?: ContractReminderFrequency
  timedelta_before_expire?: string
}

export interface ContractTypeListItemResponse {
  name: string
  id: number
}

export interface ContractTypeRequest {
  name: string
}
export interface ContractTypeResponse {
  name: string
  id: number
}
